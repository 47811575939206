import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FacebookIcon from '@mui/icons-material/Facebook';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { doc, setDoc, updateDoc, collection, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { useAuth } from '../../hooks/useAuth';
import SignInImage from '../../assets/images/signin.svg';
import { COMMON_FIREBASE_ERROR_CODE } from '../../config/errors';
import { USER_TYPE } from '../../config/const';
import { returnIsLocationChatScreen } from '../../utils/helper';
import { firestore } from '../../utils/firebase';
import TopImageSection from '../../component/TopImageSection';
import GoogleIcon from '../../svg/GoogleIcon';
import SignInEmail from '../signinEmail';
import SignUp from '../signup';
import VerifyEmail from '../verifyEmail';

interface SignInLandingProps {
  isDialog?: boolean,
  setHandleSignInModal?: React.Dispatch<React.SetStateAction<boolean>>,
  isWidget?: boolean,
  showVerifyEmail?: boolean,
  setShowVerifyEmail?: React.Dispatch<React.SetStateAction<boolean>>,
}
interface SignInLandingFormType {
  email: string;
}

const facebookLoginEnabled =
  process.env.REACT_APP_ENABLE_FACEBOOK_LOGIN === 'true';

export default function SignInLanding(props: SignInLandingProps) {
  const { isDialog = false, setHandleSignInModal = () => {}, isWidget = false, showVerifyEmail = false, setShowVerifyEmail} = props;
  const [showSignInForWidget, setShowSignInForWidget] = React.useState<boolean>(showVerifyEmail);
  const [formSubmitting, setFormSubmitting] = React.useState<boolean>(false);
  const [googleSigning, setGoogleSigning] = React.useState<boolean>(false);
  const [facebookSigning, setFacebookSigning] = React.useState<boolean>(false);
  const [isEmailExists, setEmailExists] = React.useState<boolean>(false);

  const [isRedirectToExists, setIsRedirectToExists] =
    React.useState<boolean>(false);

  const [newsletterSubscription, setNewsletterSubscription] =
    React.useState(!isWidget);

  const {
    authUser,
    isAnonymousUser,
    checkUserExistWithEmail,
    signInSignUpWithGoogle,
    signInSignUpWithFacebook,
  } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const [ redirectURL, setRedirectURL ] = React.useState('/creators')
  const [ redirectState, setRedirectState ] = React.useState(null)
  const [email, setEmail] = React.useState<string>('')

  React.useEffect(() => {
    // store redirect information

    if (location?.state) {
      if (location.state.redirectURL) setRedirectURL(location.state.redirectURL)
      if (location.state.redirectState) setRedirectState(location.state.redirectState)
    }
  }, [])

  React.useEffect(() => {
    if (!isWidget && authUser?.documentId) {
      if (!authUser?.userName || authUser?.userName === undefined) {
        navigate('/create-profile', {state: {redirectURL, redirectState}});
      }
      else if (isDialog && isAnonymousUser) {
        if (redirectState) {
          navigate(location.pathname, {state: redirectState});
        } else {
          navigate(location.pathname);
        }
      } else {
        navigate(redirectURL, {state: redirectState});
      }
    }
  }, [authUser, searchParams]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: SignInLandingFormType) => {
    setFormSubmitting(true);

    const checkEmail = values?.email?.toLowerCase()?.trim()
    setEmail(checkEmail);
    const checkEmailExists = await checkUserExistWithEmail(checkEmail)
    setEmailExists(checkEmailExists);

    if(isWidget) {
      setShowSignInForWidget(true)
    }
    else {
      if (checkEmailExists) {
        navigate('/signin-email', { state: { email: checkEmail, redirectState, redirectURL } });
      } else {
        navigate('/signup', { state: { email: checkEmail, redirectState, redirectURL } });
      }
    }
  };

  onAuthStateChanged(auth, (user) => {
    if (user) {
      // const userId = user.uid;
      setEmailOptInTrueForCurrentUser();
    } else {
      // The user is signed out
      // console.log('User is signed out');
    }
  });

  const handleNewsletterSubscriptionToggle = () => {
    setNewsletterSubscription((prevSubscription) => !prevSubscription);
  };

  const setEmailOptInTrueForCurrentUser = async () => {
    try {
      const currentUser = auth.currentUser;
      const usersCollection = collection(firestore, 'users');
      const userDocRef = doc(usersCollection, currentUser.uid);

      const docSnapshot = await getDoc(userDocRef);

      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        if (!userData.hasOwnProperty('emailOptIn')) {
          await updateDoc(userDocRef, { emailOptIn: newsletterSubscription });
          console.log('EmailOptIn field set to false for the current user.');
        } else {
          console.log('EmailOptIn field already exists for the current user.');
        }
      } else {
        await setDoc(userDocRef, { emailOptIn: newsletterSubscription });
        console.log(
          'User document created with EmailOptIn field set to false.'
        );
      }
    } catch (error) {
      console.error('Error setting EmailOptIn field:', error);
    }
  };

  const handleSignInWithGoogle = async () => {
    setGoogleSigning(true);
    const userData = {
      userType: USER_TYPE.FOLLOWER,
    };

    signInSignUpWithGoogle(userData)
      .then(() => {
        toast.success('User signed in successfully');
        setGoogleSigning(false);
        setHandleSignInModal(false);
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid login details'
        );
        setGoogleSigning(false);
        if (!isWidget) setHandleSignInModal(false);
      });
  };

  const handleSignInWithFacebook = async () => {
    setFacebookSigning(true);
    const userData = {
      userType: USER_TYPE.CREATOR,
    };
    let redirectTo = searchParams?.get('redirectTo');
    if (isAnonymousUser && returnIsLocationChatScreen(location)) {
      redirectTo = location?.pathname;
    }
    if (redirectTo) {
      userData.userType = USER_TYPE.FOLLOWER;
    }
    signInSignUpWithFacebook(userData)
      .then(() => {
        toast.success('User signed in successfully');
        setFacebookSigning(false);
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid signin details'
        );
        setFacebookSigning(false);
      });
  };

  const signinValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .strict(true)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Please enter valid email'
      ),
  });

  const handleBackButtonClick = () => {
    setFormSubmitting(false);
    setIsRedirectToExists(false);
  };

  const initialValues = {
    email: '',
  };

  return (
    <Box
      display='flex'
      flex={1}
      flexDirection='column'
      alignItems='center'
      justifyContent={isWidget ? 'center' : 'flex-start'}
      justifyItems='center'
      textAlign='center'
      width='100%'
      height={isWidget ? '100%' : 'auto'}
      sx={{
        px: isDialog ? '0' : '2rem',
      }}
    >
      <Box
        className= {isWidget ? 'widget-sign-in-modal-wrapper' : ''}
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        flex={0.5}
        width='100%'
        sx={{
          '@media (min-width: 768px)': {
            width: '50%',
          },
          '@media (min-width: 1440px)': {
            px: isDialog ? '0' : '5rem',
          },
        }}
      >
        {showSignInForWidget ?
          <>
          {
            showVerifyEmail ?
              <>
              < VerifyEmail isWidget email={email} setShowVerifyEmail={setShowVerifyEmail} setEmailExists={setEmailExists} setEmail={setEmail}/> </>
            :
            isEmailExists ?
              <>
                <SignInEmail isDialog isWidget email={email} setShowVerifyEmail={setShowVerifyEmail}/>
              </>
              :
              <>
{/*
                <button onClick={() => {
                  setShowVerifyEmail(false);
                  setShowSignInForWidget(false)}}>Back</button> */}
                <SignUp isWidget email={email}/>
              </>
          }
          </>
          :
        <Box display='flex' flexDirection='column' gap={isWidget ? 2 : 0} justifyContent='center' alignItems='center' width={isWidget ? 'auto' : '100%'}>
        {isWidget ? <Box className='widget-sign-in-header widget-common-header'>Please Create Account / Sign In to Continue</Box> : <TopImageSection image={SignInImage} title='Create Account / Sign In' />}
        <Formik
          initialValues={initialValues}
          validationSchema={signinValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isValid,
            dirty,
          }) => (
            <Form
              id={'signin-form'}
              autoComplete='off'
              style={{ width: '100%' }}
              noValidate
            >
              <Box display='flex' flexDirection='column' gap={isWidget ? 1.5 : 3} mt={-2}>
                <Box display='flex' gap={1.5} flexDirection='column'>
                  <TextField
                    type='email'
                    margin='normal'
                    fullWidth
                    id='email'
                    label='Email Address'
                    name='email'
                    autoComplete='off'
                    placeholder="Email address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={handleBackButtonClick}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  {isWidget ?
                      <Button
                        type='submit'
                        variant='contained'
                        fullWidth
                        disabled={
                          !(isValid && dirty) || formSubmitting || googleSigning
                        }
                        startIcon={
                          formSubmitting && (
                            <CircularProgress size={16} color='inherit' />
                          )
                        }
                      >
                        {'Next'}
                      </Button>

                  :
                  isValid && dirty ? (
                      <Button
                        type='submit'
                        variant='contained'
                        fullWidth
                        disabled={
                          !(isValid && dirty) || formSubmitting || googleSigning
                        }
                        startIcon={
                          formSubmitting && (
                            <CircularProgress size={16} color='inherit' />
                          )
                        }
                      >
                        {'Next'}
                      </Button>
                      ) : null
                    }
                </Box>
                <Typography>or</Typography>
                <Box display='flex'>
                  <Button
                    type='button'
                    fullWidth
                    variant='outlined'
                    disabled={
                      googleSigning || formSubmitting || isRedirectToExists
                    }
                    sx={{
                      justifyContent: 'center',
                    }}
                    onClick={handleSignInWithGoogle}
                    startIcon={
                      <Box display='flex' alignItems='center'>
                        {googleSigning && (
                          <CircularProgress
                            size={20}
                            color='inherit'
                            sx={{ marginRight: 2 }}
                          />
                        )}
                        <GoogleIcon height={'30px'} width='30px' />
                      </Box>
                    }
                  >
                    {'Continue with Google'}
                  </Button>
                  {facebookLoginEnabled && (
                    <Button
                      type='button'
                      variant='outlined'
                      disabled={
                        facebookSigning || formSubmitting || isRedirectToExists
                      }
                      onClick={handleSignInWithFacebook}
                      sx={{ mt: 2, mb: 2, px: 4 }}
                      startIcon={
                        <>
                          {facebookSigning && (
                            <CircularProgress
                              size={16}
                              color='inherit'
                              sx={{ marginRight: 1 }}
                            />
                          )}
                          <FacebookIcon />
                        </>
                      }
                    >
                      {'Login with Facebook'}
                    </Button>
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        {!isWidget && <Box
          display='flex'
          flexDirection='column'
          maxWidth='280px'
          mt={isWidget ? 0 : 6}
          gap={2}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={newsletterSubscription}
                onChange={handleNewsletterSubscriptionToggle}
                color='primary'
              />
            }
            label={
              <Typography
                component='span'
                variant='body1'
                fontWeight='600'
                fontSize='0.8rem'
                color='#000'
              >
                I agree to subscribe to Soopra.ai and receive email
                communications.
              </Typography>
            }
          />
          <Typography fontWeight='600' fontSize='0.8rem' color='#000'>
            By signing up, I agree to the{' '}
            <a
              href='/terms'
              style={{ color: '#4B9CCD', textDecoration: 'underline' }}
            >
              Terms and Conditions and Privacy Policy
            </a>
          </Typography>
        </Box>}
      </Box>
      }
      </Box>
    </Box>
  );
}
