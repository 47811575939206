import {
  CourseMessageRequest,
  EditedMessagesRequest,
  SendMessageRequest,
  TTSRequest,
} from '../views/userChat/messages.types';

const accessToken = process.env.REACT_APP_API_REQUEST_ACCESS_TOKEN;
const internalToken = process.env.REACT_APP_API_INTERNAL_ACCESS_TOKEN;
const unsplashAccessKey = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;

export interface DeleteDataRequest {
  user_name?: string;
  user_id?: string;
  delete_profile?: boolean;
  delete_index?: boolean;
  delete_conversation?: DeleteConversationRequest;
  delete_notifications?: boolean;
  delete_training_info?: boolean;
  delete_documents?: boolean;
  delete_webpages?: boolean;
}

export interface DeleteConversationRequest {
  creator_id: string;
}

export function cloneVoice(userId: string): any {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}voices/clone`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  let jsonString = { user_id: '' + userId + '' };
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(jsonString),
  };
  return fetch(apiURL, requestOptions);
}

export function deleteVoice(userId: string): any {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}voices/delete`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  let jsonString = { user_id: '' + userId + '' };
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(jsonString),
  };
  return fetch(apiURL, requestOptions);
}

const removeEmojis = (str: string) => {
  return str.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    .replace(/\s+/g, ' ')
    .replaceAll(/[^a-zA-Z]+$/gi, "")
    .trim();
}

export function getTextToSpeech(data: TTSRequest): any {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}voices/tts`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  data.text = removeEmojis(data.text);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(data),
  };
  const audioCtx = new AudioContext();
  return fetch(apiURL, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error, status = ${response.status}`);
      }
      return response.arrayBuffer();
    })
    .then((buffer) => audioCtx.decodeAudioData(buffer))
    .then((decodedData) => {
      const source = audioCtx.createBufferSource();
      const analyzer = audioCtx.createAnalyser();
      const gainNode = audioCtx.createGain();
      gainNode.gain.value = 1;
      source.buffer = decodedData;
      source.connect(analyzer);
      analyzer.connect(gainNode)
      gainNode.connect(audioCtx.destination)
      return [source, analyzer, gainNode];
    });
}

export function sendMessageToSmartAssistant(
  data: SendMessageRequest,
  userType: string
): any {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}chat`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(apiURL, requestOptions);
}
export function buildPersonality(documentId: string): any {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}indexer`;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  let jsonString = { doc_id: '' + documentId + '' };
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(jsonString),
  };

  return fetch(apiURL, requestOptions);
}

export function editedMessageResponse(data: EditedMessagesRequest): any {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}insert_message`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(apiURL, requestOptions);
}

/**
 * @function updatePersona
 * @description This function sends a POST request to the index_all API endpoint.
 * This endpoint indexes only the documents and the links to content provided by the creator for which
 *   the attribute "is_indexed" is false in the user profile. This should be called only when a creator updates profile.
 * @param {string} userId The ID (user documentId in firebase) of the user whose index needs to be updated.
 * @returns {any} The response from the API endpoint.
 */
export function updatePersona(
  userId: string,
  data: { [key: string]: boolean }
): any {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}index_all`;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  // Create a new JSON object.
  const jsonObject = {
    user_id: userId,
  };
  // Add the items that need to be indexed to the jsonObject.
  const updatedJsonObject = {
    ...jsonObject,
    ...data,
  };
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(updatedJsonObject),
  };

  return fetch(apiURL, requestOptions);
}

export function deleteData(data: DeleteDataRequest): any {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}delete_data`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  myHeaders.append('int-access-token', internalToken);
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(apiURL, requestOptions);
}

export function clearChat(roomId: string): any {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}messages/clear_chat?room_id=${roomId}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  myHeaders.append('int-access-token', internalToken);
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'DELETE'
  };
  return fetch(apiURL, requestOptions);
}


/**
 * @function deleteFromPersona
 * @description This function sends a POST request to the delete_from_index API endpoint.
 * Deletes document information with given llama_index_doc_ids from the llama index.
 * To be called before/during deleting the files from creator's profile when creator deletes them.
 *
 * @param userId The ID (user documentId in firebase) of the user.
 * @param llama_index_doc_id
 * @returns The response from the API.
 */
export function deleteFromPersona(
  userId: string,
  llama_index_doc_id: string
): any {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}delete_from_index`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  // Create a new JSON object.
  const jsonObject = {
    user_id: userId,
  };
  // Add the llama_index_doc_id list to the JSON object.
  jsonObject['llama_index_doc_id'] = llama_index_doc_id;

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(jsonObject),
  };
  return fetch(apiURL, requestOptions);
}

// get the train questions from given creator Id and level Id.
export async function fetchLevelQuestionsData(queryParams: any): Promise<any> {
  try {
    const creatorId = queryParams?.queryKey?.[1]; // creator Id
    const levelId = queryParams?.queryKey?.[2]; // level Id
    const apiURL = `${process.env.REACT_APP_API_END_POINT}get_training_questions`;
    const jsonString = {
      user_id: creatorId,
      level: levelId,
    };

    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', accessToken);

    const requestOptions: RequestInit = {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(jsonString),
    };

    const response = await fetch(apiURL, requestOptions);
    return response?.json();
  } catch (err) {
    console.log('fetch-level-data-questions-error', err);
    throw err;
  }
}

// update the train questions with user response on given creator Id and level Id.
export async function updateLevelQuestionsData(
  updatedLevelData: any
): Promise<any> {
  try {
    const apiURL = `${process.env.REACT_APP_API_END_POINT}update_training_questions`;
    let myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', accessToken);

    const requestOptions: RequestInit = {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(updatedLevelData),
    };
    const response = await fetch(apiURL, requestOptions);
    return response?.json();
  } catch (err) {
    console.log('update-level-data-questions-error', err);
    throw err;
  }
}

// get the all levels progress and status data
export async function fetchCreatorLevelInfo(queryParams: any): Promise<any> {
  try {
    const creatorId = queryParams?.queryKey?.[1]; //creator Id
    const apiURL = `${process.env.REACT_APP_API_END_POINT}get_train_level_info`;
    const jsonString = { user_id: creatorId };

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', accessToken);

    const requestOptions: RequestInit = {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(jsonString),
    };

    const response = await fetch(apiURL, requestOptions);
    return response?.json();
  } catch (err) {
    console.log('fetch-level-questions-error', err);
  }
}

export function uploadFacebookAuthResponse(response: any): any {
  try {
    const apiURL = `${process.env.REACT_APP_API_END_POINT}auth/facebook`;
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', accessToken);
    const requestOptions: RequestInit = {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(response),
    };
    return fetch(apiURL, requestOptions);
  } catch (err) {
    console.log('Facebook-authentication-error', err);
  }
}

export function getNotifications(userId: string, page?: number, pageSize?: number): any {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}notifications`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  let jsonString = {
    user_id: userId,
    page: page,
    pageSize: pageSize,
   };
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(jsonString),
  };
  return fetch(apiURL, requestOptions);
}

export function markNotificationRead(
  userId: string,
  notificationId: string
): any {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}notifications`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  const jsonObject = {
    user_id: userId,
    notification_id: notificationId,
  };

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'PUT',
    body: JSON.stringify(jsonObject),
  };
  return fetch(apiURL, requestOptions);
}

export function getSortedCreatorList(
  userId: string,
  page?: number,
  pageSize?: number,
  sortBy?: string
): any {
  page ||= 1;
  pageSize ||= 10;
  sortBy ||= 'oldestFirst';
  const apiURL = `${process.env.REACT_APP_API_END_POINT}creators/${userId}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET',
  };

  return fetch(apiURL, requestOptions);
};

export function getIntroMessage(
  userId: string,
  generateNew?: boolean,
): any {
    generateNew ||= false;
    let apiURL = `${process.env.REACT_APP_API_END_POINT}intro_message/${userId}?generateNew=${generateNew}`;
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', accessToken);

    const requestOptions: RequestInit = {
      headers: myHeaders,
      method: 'GET',
    };

    return fetch(apiURL, requestOptions);
};

export function getCreatorsList(
  userId: string,
  searchQuery: string,
  page?: number,
  pageSize?: number,
):any {
  page ||= 1;
  pageSize ||= 10;
  const apiURL = `${process.env.REACT_APP_API_END_POINT}search/${userId}?page=${page}&pageSize=${pageSize}&query=${searchQuery}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET',
  };

  return fetch(apiURL, requestOptions);
};

export function getCorporateAccounts(enterpriseName, page = 1, pageSize = 10, sortBy = 'aToz') {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}/corporate_accounts/${enterpriseName}?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}`;
  const headers = new Headers({
    'Content-Type': 'application/json',
    'x-access-token': accessToken, // Make sure you have defined or obtained accessToken appropriately
  });

  const requestOptions = {
    method: 'GET',
    headers: headers,
  };

  return fetch(apiURL, requestOptions);
}

export function sendEmailWithTemplate(email) {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}/send-email-with-template`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken); // Assuming accessToken is already defined and holds the authentication token

  const emailData = {
    email: email, // Email address to which the template email will be sent
  };

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(emailData),
  };

  return fetch(apiURL, requestOptions)
    .then(response => {
      if (!response.ok) {
        // If the server response was not OK, throw an error
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); // Parse the JSON response body
    })
    .then(data => {
      console.log('Email sent successfully:', data);
      return data; // Return the data to handle it where the function was called
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });
}

export function sendCourseEnrollmentConfirmation(email: string, user_name: string, course_link: string, course_name: string): Promise<any> {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}send-course-enrollment-confirmation`;
  const myHeaders = new Headers();

  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const emailData = {
    email: email,
    user_name: user_name,
    course_link: course_link,
    course_name: course_name
  };

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(emailData),
  };

  return fetch(apiURL, requestOptions)
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          throw new Error(`HTTP error! status: ${response.status}, message: ${err.message}`);
        });
      }
      return response.json();
    })
    .then(data => {
      console.log('Course enrollment confirmation email sent successfully:', data);
      return data;
    })
    .catch((error) => {
      console.error('Error sending course enrollment confirmation email:', error);
    });
}

export function sendCourseCompletionEmail(email: string, user_name: string, course_name: string, teacher_name: string, courses_link: string): Promise<any> {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}send-course-completion-email`;
  const myHeaders = new Headers();

  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const emailData = {
    email: email,
    user_name: user_name,
    course_name: course_name,
    teacher_name: teacher_name,
    courses_link: courses_link
  };

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(emailData),
  };

  return fetch(apiURL, requestOptions)
    .then(response => {
      if (!response.ok) {
        return response.json().then(err => {
          throw new Error(`HTTP error! status: ${response.status}, message: ${err.message}`);
        });
      }
      return response.json();
    })
}

////////////////////////////////////////////////////////////////////
// Course related functions
////////////////////////////////////////////////////////////////////

// placeholder until course search exists
export function searchCoursesList(
  searchQuery: string,
  page?: number,
  pageSize?: number,
) {
  page ||= 1;
  pageSize ||= 10;
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/search?page=${page}&pageSize=${pageSize}&query=${searchQuery}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET',
  };

  return fetch(apiURL, requestOptions);
}

export function getSortedCoursesList(
  page?: number,
  pageSize?: number,
  filterBy?: string
) {
  page ||= 1;
  pageSize ||= 10;
  filterBy ||= 'None';
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/search?page=${page}&pageSize=${pageSize}&filterBy=${filterBy}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET',
  };

  return fetch(apiURL, requestOptions);
}

export function getCourseInfo(
  courseId: string
) {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/?course_id=${courseId}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET'
  };

  return fetch(apiURL, requestOptions);
}

// Sends course data to backend, recieves course_id in return
export const createCourseDoc = async (userId, courseTitle, subject, grade, coursePrompt, numModules, teachingTone) => {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/`;
  const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'x-access-token': accessToken // Adding the access token header
  });

  const requestBody = {
    course_title: courseTitle,
    user_id: userId,
    user_description: coursePrompt,
    subject: subject,
    grade: grade,
    module_length: numModules,
    teaching_tone: teachingTone,
  };

  const requestOptions = {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(requestBody),
  };

  try {
    const response = await fetch(apiURL, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Error in createCurriculum API call:', error);
    throw error;
  }
};

export function generateCurriculum(courseId, userId, courseDocuments) {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/index_and_generate_curriculum`;
  const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'x-access-token': accessToken // Adding the access token header
  });

  const requestBody = {
    course_id: courseId,
    course_documents: courseDocuments,
    user_id: userId,
  };

  const requestOptions = {
      headers: myHeaders,
      method: 'PUT',
      body: JSON.stringify(requestBody),
  };

  return fetch(apiURL, requestOptions).then(response => {
      if (!response.ok) {
          return response.json().then(errorInfo => {
              console.error('Error response from server:', errorInfo);
              throw new Error(`HTTP error! Status: ${response.status}`);
          });
      }
      return response.json();
  });
}


export function updateCurriculum(courseId, updatedCourseInfo, creationDone = false) {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/`;
  const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'x-access-token': accessToken // Adding the access token header
  });

  const requestBody = {
    course_id: courseId,
    creation_done: creationDone,
    ...updatedCourseInfo
  };

  const requestOptions = {
      headers: myHeaders,
      method: 'PUT',
      body: JSON.stringify(requestBody),
  };

  return fetch(apiURL, requestOptions).then(response => {
      if (!response.ok) {
          return response.json().then(errorInfo => {
              console.error('Error response from server:', errorInfo);
              throw new Error(`HTTP error! Status: ${response.status}`);
          });
      }
      return response.json();
  });
}

export async function fetchCourseIntroMessage(courseId: string, generateNew: boolean) {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}courses/gen_intro?course_id=${courseId}&generate_new=${generateNew}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET'
  };

  return fetch(apiURL, requestOptions);
}

export async function setCourseIntroMessage(courseId: string, message: string) {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}courses/gen_intro`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify({course_id: courseId, message: message}),
  };

  try {
    const response = await fetch(apiURL, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Error in setCourseIntroMessage API call:', error);
    throw error;
  }
}


export function sendMessageInCourse(
  data: CourseMessageRequest
): any {
  let apiURL = `${process.env.REACT_APP_API_END_POINT}courses/instruct`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(data),
  };
  return fetch(apiURL, requestOptions);
}


export const fetchCoursesByCreator = async (creatorId) => {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/list/${creatorId}`;
  const myHeaders = new Headers();
  myHeaders.append('x-access-token', accessToken);

  const requestOptions = {
      method: 'GET',
      headers: myHeaders,
  };
  try {
    const response = await fetch(apiURL, requestOptions );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const courses = await response.json();
    return courses.filter(course => course.creation_done === true);
  } catch (error) {
    console.error('Error in fetchCoursesByCreator API call:', error);
    throw error;
  }
};

export const fetchCourseResultsByStudent = async (studentId: string) => {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/results?student_id=${studentId}`;
  const myHeaders = new Headers();
  myHeaders.append('x-access-token', accessToken);

  const requestOptions = {
      method: 'GET',
      headers: myHeaders,
  };
  try {
    const response = await fetch(apiURL, requestOptions );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const results = await response.json();
    return results;
  } catch (error) {
    console.error('Error in fetchCourseResultsByStudent API call:', error);
    throw error;
  }
};

export const fetchResultsByStudentAndCourse = async (studentId: string, courseId: string) => {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/results?student_id=${studentId}&course_id=${courseId}`;
  const myHeaders = new Headers();
  myHeaders.append('x-access-token', accessToken);

  const requestOptions = {
      method: 'GET',
      headers: myHeaders,
  };
  try {
    const response = await fetch(apiURL, requestOptions );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const results = await response.json();
    return results;
  } catch (error) {
    console.error('Error in fetchResultsByStudentAndCourse API call:', error);
    throw error;
  }
}

export const fetchResultsByCourse = async (courseId: string) => {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/results?course_id=${courseId}`;
  const myHeaders = new Headers();
  myHeaders.append('x-access-token', accessToken);

  const requestOptions = {
      method: 'GET',
      headers: myHeaders,
  };
  try {
    const response = await fetch(apiURL, requestOptions );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const results = await response.json();
    return results;
  } catch (error) {
    console.error('Error in fetchResultsByCourse API call:', error);
    throw error;
  }
}

export const getCurriculumDetails = async (courseId) => {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/${courseId}`;
  const myHeaders = new Headers();
  myHeaders.append('x-access-token', accessToken);

  const requestOptions = {
      method: 'GET',
      headers: myHeaders,
  };

  try {
      const response = await fetch(apiURL, requestOptions);
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
  } catch (error) {
      console.error('Error in getCurriculumDetails API call:', error);
      throw error;
  }
};


export function deleteCourse(courseId) {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/`;
  const myHeaders = new Headers({
    'Content-Type': 'application/json',
    'x-access-token': accessToken // Adding the access token header
  });

  const requestBody = {
    course_id: courseId
  };

  const requestOptions = {
    headers: myHeaders,
    method: 'DELETE',
    body: JSON.stringify(requestBody)
  };

  return fetch(apiURL, requestOptions)
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorInfo => {
          console.error('Error response from server:', errorInfo);
          throw new Error(`HTTP error! Status: ${response.status}`);
        });
      }
      return response.json();
    })
    .catch(error => {
      console.error('Error in deleteCourse API call:', error);
      throw error;
    });
}


export async function fetchCourseProgress(roomId: string): Promise<any> {
  const encodedRoomId = encodeURIComponent(roomId);
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/progress?room_id=${encodedRoomId}`;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const requestOptions = {
    headers: myHeaders,
    method: 'GET',
  };

  try {
    const response = await fetch(apiURL, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching course progress:', error);
    throw error;  // Ensuring that this error is thrown to be caught by the outer catch block
  }
}

export async function updateCourseResults(courseId: string, studentId: string, moduleNum: number, results: any): Promise<any> {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}courses/results`;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);

  const resultsData = {
    score: results.filter(result => result.isCorrect).length,
    questions: results.map(result => ({
      question: result.question,
      selectedOption: result.selectedOption,
      correctOption: result.correctOption,
      isCorrect: result.isCorrect
    })),
    module_num: moduleNum,
  };

  const requestBody = {
    course_id: courseId,
    student_id: studentId,
    results: resultsData,
  };

  const requestOptions = {
    headers: myHeaders,
    method: 'PUT',
    body: JSON.stringify(requestBody)
  };

  try {
    const response = await fetch(apiURL, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error updating course results:', error);
    throw error;  // Ensuring that this error is thrown to be caught by the outer catch block
  }
}


export const uploadCourseBanner = async (course_id: string, unsplash_url: string) => {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}upload_image`;
  const formData = new FormData();

  formData.append('course_id', course_id);
  formData.append('unsplash_url', unsplash_url);

  const myHeaders = new Headers();
  myHeaders.append('x-access-token', accessToken);

  const requestOptions = {
    method: 'POST',
    body: formData,
    headers: myHeaders
  };

  try {
    const response = await fetch(apiURL, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return;
  } catch (error) {
    console.error('Error in uploadCourseBanner API call:', error);
    throw error;
  }
};

export const searchUnsplash = async (query: string, page: number) => {
  const apiURL = `https://api.unsplash.com/search/photos?page=${page}&query=${query}&client_id=${unsplashAccessKey}&orientation=landscape`;

  try {
    const response = await fetch(apiURL);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error('Error in searchUnsplash API call:', error);
    throw error
  }
}


////////////////////////////////////////////////////////////////////
// Monetization related functions
////////////////////////////////////////////////////////////////////

// export async function createPaymentIntent(user_id, items) {
//   const apiURL = `${process.env.REACT_APP_API_END_POINT}payments/${user_id}`;
//   var myHeaders = new Headers();
//   myHeaders.append('Content-Type', 'application/json');
//   myHeaders.append('x-access-token', accessToken);
//   let jsonString = { items };
//   const requestOptions: RequestInit = {
//     headers: myHeaders,
//     method: 'POST',
//     body: JSON.stringify(jsonString),
//   };
//   return fetch(apiURL, requestOptions);
// }

export async function createCheckoutSession(user_id: string, email: string, item: string, customerId?: string, itemId?: string, redirectURL?: string, isPremium?: boolean, trialPeriod?: number) {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}checkout-session/${user_id}`;
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-access-token', accessToken);
    let jsonString = { 'email': email,
                      'item': item
                     };
    if (customerId) jsonString['customer'] = customerId;
    if (itemId) jsonString['item_id'] = itemId;
    if (redirectURL) jsonString['redirect_url'] = redirectURL;
    if (isPremium) {
      jsonString['is_premium'] = true;
    }
    if (trialPeriod) jsonString['trial_period'] = trialPeriod;
    const requestOptions: RequestInit = {
      headers: myHeaders,
      method: 'POST',
      body: JSON.stringify(jsonString),
    };
    return fetch(apiURL, requestOptions);
}

export async function getPaymentsHistory(user_id: string, page?: number, page_size?: number){
  page ||= 1;
  page_size ||= 10;
  const apiURL = `${process.env.REACT_APP_API_END_POINT}payments/${user_id}?page=${page}&page_size=${page_size}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET',
  };
  return fetch(apiURL, requestOptions);
}

export async function getCheckoutSessionStatus(user_id: string, session_id: string){
  const apiURL = `${process.env.REACT_APP_API_END_POINT}checkout-session/${user_id}?session_id=${session_id}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET',
  };
  return fetch(apiURL, requestOptions);
}

export async function getPrices(items: string[]){
  let itemsString = "[" + items.join(", ") + "]";
  const apiURL = `${process.env.REACT_APP_API_END_POINT}prices?items=${itemsString}`;
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-access-token', accessToken);
  const requestOptions: RequestInit = {
    headers: myHeaders,
    method: 'GET',
  };
  return fetch(apiURL, requestOptions);
}

////////////////////////////////////////////////////////////////////


// API call to get the Twitter auth URL
export async function getTwitterAuthUrl(): Promise<string> {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}twitter/auth-url`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'x-access-token': process.env.REACT_APP_API_REQUEST_ACCESS_TOKEN,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch Twitter auth URL');
  }

  const data = await response.json();
  return data.url;
}

export async function handleTwitterCallback(code: string, userId: string): Promise<any> {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}twitter/callback?code=${code}&user_id=${userId}`;

  try {
    const response = await fetch(apiURL, {
      method: 'GET',
      headers: {
        'x-access-token': process.env.REACT_APP_API_REQUEST_ACCESS_TOKEN,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Twitter user data');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error handling Twitter callback:', error);
    throw error;
  }
}


export async function fetchTwitterData(userId: string): Promise<any> {
  const apiURL = `${process.env.REACT_APP_API_END_POINT}twitter/data/${userId}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'x-access-token': accessToken,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch Twitter data');
  }

  const data = await response.json();
  return data;
}
